import React, { useEffect, useState } from "react";
import { Button, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { eventsCollection, PLATFORM_WIDGET_CDN_PREFIX } from "../../PlatformWidget";

import Jitsi from "../../jitsi/Jitsi";
import { JitsiMeetAPIOptions } from "../../jitsi/types";
import firebase from "firebase";

interface TableItem {
    id: string;
    name: string;
    description?: string;
    logo: string;
    attendees: any[];
}

interface TableProps {
    currentUserId: string;
    eventName: string;
    cUser: firebase.User;
}

export default function Table(props: TableProps) {
    const { t, i18n } = useTranslation();
    const [tables, setTables] = useState<any[]>([]);
    const [tablesWithAttendees, setTablesWithAttendees] = useState<any[]>([]);
    const [attendees, setAttendees] = useState<any>([]);
    const [tableSelected, setTableSelected] = useState<string | null>(null);
    const [userInfo, setUserInfo] = useState<JitsiMeetAPIOptions["userInfo"]>(undefined);
    const [searchInput, setSearchInput] = useState('');

    useEffect(() => {
        const ret = tables.map(table => {
            table.attendees = attendees.filter((attendee: any) => attendee.table === table.id);
            return table;
        });
        setTablesWithAttendees(ret);
    }, [attendees, tables]);

    useEffect(() => {
        eventsCollection.doc(props.eventName).collection("tables").orderBy("name").onSnapshot(
            (querySnapshot: any) => {
                const tableList: any[] = [];
                querySnapshot.forEach((doc: any) => {
                    tableList.push({ id: doc.id, ...doc.data() });
                });
                setTimeout(() => {
                    setTables(tableList);
                }, 0);
            });
    }, []);

    useEffect(() => {
        eventsCollection.doc(props.eventName).collection("attendees").where("table", "!=", null).onSnapshot(
            (querySnapshot: any) => {
                const attendeeList: any[] = [];
                querySnapshot.forEach((doc: any) => {
                    attendeeList.push({id: doc.id, ...doc.data()});
                });
                setTimeout(() => {
                    // attendeeList = attendeeList.filter(attendee => attendee.lastConnection >= today);
                    setAttendees(attendeeList);
                }, 0);
            });
    }, []);

    const handleSearchInputChange = (event: any) => {
        setSearchInput(event.target.value);
    };

    const selectTable = (id: string | null) => {
        eventsCollection.doc(props.eventName).collection("attendees").doc(props.currentUserId).get().then((snapshot: any) => {
            const user: any = snapshot.data();
            console.log(user);
            setUserInfo({
                displayName: props.cUser.displayName ?? undefined,
                avatarURL: props.cUser.photoURL ?? undefined
            });
            setTableSelected(id);
            // eventsCollection.doc(props.eventName).collection("attendees").doc(props.currentUserId).update({
            //     table: id
            // }).then(() => {
            // });
        })
    };

    const filterTables = (table: TableItem) => {
        return table.name.toLowerCase().includes(searchInput.toLowerCase()) ||
            table.description?.toLowerCase().includes(searchInput.toLowerCase()) || table.attendees?.filter(attendee => attendee.username.toLowerCase().includes(searchInput.toLowerCase())).length > 0;
    };

    const TableListItem = ({ table }: { table: TableItem }) =>
    (<div className='tw-flex tw-flex-row tw-items-center tw-p-1 tw-m-2 tw-rounded-lg' style={{ backgroundColor: '#000000' }}>
        <div className='tw-flex tw-flex-row tw-items-center'>
            {/*<img src={`${PLATFORM_WIDGET_CDN_PREFIX}/assets/images/${props.eventName}/${table.logo}`} alt={t('table.logo.alt')}/>*/}
            <div className='tw-ml-2 tw-text-white tw-font-medium'>
                {table.name} {table.description ? <>&bull; {table.description}</> : ''}
            </div>
        </div>
        <div className='tw-flex tw-flex-grow tw-flex-row-reverse tw-items-center'>
            <button
                type='button'
                className='tw-justify-end tw-px-2 tw-py-1 tw-rounded-lg tw-text-black'
                style={{ backgroundColor: '#CCC8C6' }}
                onClick={() => selectTable(table.id)}>
                {t('table.joinBtn')}
            </button>
            {table.attendees?.map(attendee => attendee.avatar && <img className='tw-self-center tw-rounded-full tw-inline-block tw-mr-2' style={{ width: '40px' }} src={attendee.avatar} alt='avatar' title={attendee.username} key={attendee.id} />)}
        </div>
    </div>);

    return (
        <div className='tw-bg-white' style={{ height: '400px' }}>
            {tableSelected &&
                <div className='tw-grid tw-place-items-center'>
                    <Jitsi roomName={'crmcf' + tableSelected}
                        userInfo={userInfo}
                        frameStyle={{ width: '100%', height: '100%' }} onVideoConferenceLeft={() => selectTable(null)} />
                </div>}
            {!tableSelected &&
                <div className='tw-flex tw-flex-col tw-h-full'>
                    <div className='tw-flex'>
                        <input type="text" placeholder={t('table.searchPlaceholder')} value={searchInput}
                            style={{ border: `3px solid #F7BACB`, borderRadius: '4px', padding: '0.5rem' }}
                            className="tw-flex-auto" onChange={handleSearchInputChange} />
                    </div>
                    <div className='tw-flex-auto tw-overflow-y-scroll custom-scroll'>
                        {tablesWithAttendees.filter(filterTables).map(table => (<TableListItem key={table.id} table={table} />))}
                    </div>
                </div>}
        </div>
    );
}
