import React from "react";
import { eventsCollection } from "../../PlatformWidget";
import LoginForm from "./LoginForm";


export default function LoginPage(props: any) {
  return (
      <div className='tw-min-h-screen tw-min-h-full tw-grid' style={{backgroundColor: '#252B4B'}}>
        <LoginForm triggerConnection={props.triggerConnection} eventName={props.eventName}/>
      </div>
  );
}