import React, {useReducer} from "react";
import {Tab, Tabs} from "react-bootstrap";
import {Button, Form, Modal} from "react-bootstrap";
import {EventDetails} from "./Manage";

interface CreateOrUpdateEventModalProps {
  eventDetails?: EventDetails;
  onCreateEvent: (event: EventDetails) => void;
  onClose: () => void;
}

const reducer = (state: any, action: { type: any; payload: any; }) => {
  switch (action.type) {
    case 'eventName':
      return {...state, eventName: action.payload};
    case 'numGuests':
      return {...state, numGuests: action.payload};
    case 'numTables':
      return {...state, numTables: Number(action.payload)};
    case 'usersPerTable':
      return {...state, usersPerTable: Number(action.payload)};
    case 'eventType':
      return {...state, eventType: action.payload};
    case 'streamLink':
      return {...state, streamLink: action.payload};
    case 'broadcaster.name':
      return {...state, broadcaster: {...state.broadcaster, name: action.payload}};
    case 'broadcaster.image':
      return {...state, broadcaster: {...state.broadcaster, image: action.payload}};
    case 'broadcaster.description':
      return {...state, broadcaster: {...state.broadcaster, description: action.payload}};
    case 'broadcaster.socialMedias':
      return {
        ...state,
        broadcaster: {
          ...state.broadcaster,
          socialMedias: {...state.broadcaster?.socialMedias, [action.payload.key]: action.payload.value}
        }
      };
    default:
      throw new Error();
  }
}

const socialMedias = {
  'instagram': {
    text: 'Instagram link',
    icon: 'fa-instagram',
  },
  'twitter': {
    text: 'Twitter link',
    icon: 'fa-twitter',
  },
  'tiktok': {
    text: 'Tiktok link',
    icon: 'fa-tiktok',
  },
  'youtube': {
    text: 'Youtube link',
    icon: 'fa-youtube',
  },
}

export const CreateOrUpdateEventModal: React.FC<CreateOrUpdateEventModalProps> = ({
                                                                                    eventDetails,
                                                                                    onCreateEvent,
                                                                                    onClose
                                                                                  }) => {

  const [event, setEvent] = useReducer(reducer, eventDetails?.id ? eventDetails : {
    eventName: '',
    numGuests: '<10',
    numTables: 1,
    usersPerTable: 8,
    eventType: 'Networking',
    streamLink: '',
    broadcaster: {}
  });
  return <Modal show={true} onHide={onClose} size="lg" contentClassName="tw-bg-blue-200">
    <Modal.Header closeButton>
      <span className="tw-font-bold">
        {event.id ? `Update ${event.eventName}` : 'Create a new event'}
      </span>
    </Modal.Header>
    <Modal.Body>
      <div className="tw-grid tw-grid-cols-6 tw-gap-4">
        <div className="tw-col-span-2">
          <div className="tw-bg-red-300 p-3 tw-rounded-lg">
            <div className="tw-font-bold">Disclaimer</div>
            Your event will only be available for 24 hours before it is disabled.
          </div>
        </div>
        <div className="tw-col-span-4 p-3 tw-rounded-lg tw-bg-white">
          <Tabs defaultActiveKey="event" id="uncontrolled-tab-example">
            <Tab eventKey="event" title="Event">
              <div className="d-flex flex-column mt-2">
                <div className="d-flex justify-content-center">
                  <div className="d-flex tw-w-96">
                    <Form>
                      <Form.Group controlId="formEventName">
                        <Form.Label>Event name</Form.Label>
                        <Form.Control type="text" placeholder="Event name" value={event.eventName}
                                      onChange={(e) => setEvent({type: 'eventName', payload: e.target.value})}/>
                        <Form.Text className="text-muted">
                          We'll never share your email with anyone else.
                        </Form.Text>
                      </Form.Group>
                      <Form.Group controlId="formNumGuest">
                        <Form.Label>Number of guests</Form.Label>
                        <Form.Control as="select" value={event.numGuests}
                                      onChange={(e) => setEvent({type: 'numGuests', payload: e.target.value})}>
                          <option value={'<10'}>{'<10'}</option>
                          <option value={'10-50'}>10-50</option>
                          <option disabled>50+ (Coming soon!)</option>
                        </Form.Control>
                        <Form.Text className="text-muted">
                          We'll never share your email with anyone else.
                        </Form.Text>
                      </Form.Group>
                      <Form.Group controlId="formNumTables">
                        <Form.Label>Number of tables</Form.Label>
                        <Form.Control type="number" placeholder="Number of tables" isInvalid={event.numTables > 5}
                                      value={event.numTables}
                                      onChange={(e) => setEvent({type: 'numTables', payload: e.target.value})}/>
                        <Form.Control.Feedback type="invalid">Maximum 5 tables allowed for now</Form.Control.Feedback>
                        <Form.Text className="text-muted">
                          We'll never share your email with anyone else.
                        </Form.Text>
                      </Form.Group>
                      <Form.Group controlId="formUsersPerTable">
                        <Form.Label>Users per tables</Form.Label>
                        <Form.Control type="number" placeholder="Users per tables" isInvalid={event.usersPerTable > 10}
                                      value={event.usersPerTable}
                                      onChange={(e) => setEvent({type: 'usersPerTable', payload: e.target.value})}/>
                        <Form.Control.Feedback type="invalid">Maximum 10 users per table</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="formEventType">
                        <Form.Label>Type of event</Form.Label>
                        <Form.Control as="select" value={event.eventType}
                                      onChange={(e) => setEvent({type: 'eventType', payload: e.target.value})}>
                          <option value={'Networking'}>Networking</option>
                          <option value={'Birthday'}>Birthday</option>
                          <option value={'Graduation'}>Graduation</option>
                          <option value={'Professional Events'}>Professional Events</option>
                          <option value={'Other'}>Other</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group controlId="formStreamLink">
                        <Form.Label>Stream link</Form.Label>
                        <Form.Control type="text" placeholder="Stream link" value={event.streamLink}
                                      onChange={(e) => setEvent({type: 'streamLink', payload: e.target.value})}/>
                        <Form.Text className="text-muted">
                          The embedded link for Youtube, twitch, or whatever service you're using.
                        </Form.Text>
                      </Form.Group>
                    </Form>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="broadcaster" title="Broadcaster">
              <div className="d-flex flex-column mt-2">
                <div className="d-flex justify-content-center">
                  <div className="d-flex tw-w-96">
                    <Form>
                      <Form.Group controlId="formBroadcasterName">
                        <Form.Label>Broadcaster name</Form.Label>
                        <Form.Control type="text" placeholder="Broadcaster name" value={event.broadcaster?.name}
                                      onChange={(e) => setEvent({type: 'broadcaster.name', payload: e.target.value})}/>
                        <Form.Text className="text-muted">
                          This name will appear under the stream.
                        </Form.Text>
                      </Form.Group>
                      <Form.Group controlId="formBroadcasterImage">
                        <Form.Label>Image for the broadcaster</Form.Label>
                        Coming Soon.
                        <Form.Text className="text-muted">
                          We'll never share your email with anyone else.
                        </Form.Text>
                      </Form.Group>
                      <Form.Group controlId="formBroadcasterDescription">
                        <Form.Label>Broadcaster description</Form.Label>
                        <Form.Control type="text" placeholder="Broadcaster description"
                                      value={event.broadcaster?.description}
                                      onChange={(e) => setEvent({
                                        type: 'broadcaster.description',
                                        payload: e.target.value
                                      })}/>
                        <Form.Text className="text-muted">
                          This description will appear under the stream.
                        </Form.Text>
                      </Form.Group>
                      {Object.entries(socialMedias).map(([key, value], index) => {
                        return <Form.Group controlId={`formSocialMedia-${key}`}>
                          <Form.Label><i className={`fab ${value.icon} mr-1`}/>{value.text}</Form.Label>
                          <Form.Control type="text" placeholder={`${value.text}`}
                                        value={event.broadcaster?.socialMedias?.[key]}
                                        onChange={(e) => setEvent({
                                          type: 'broadcaster.socialMedias',
                                          payload: {key, value: e.target.value}
                                        })}/>
                        </Form.Group>
                      })}
                      <Form.Text className="text-muted">
                        Those social media links will appear as icons under the stream.
                      </Form.Text>
                    </Form>
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
          <Button className="float-right" variant="primary" type="button" onClick={() => onCreateEvent(event)}>
            {event.id ? 'Update event' : 'Create event'}
          </Button>
        </div>
      </div>

    </Modal.Body>
  </Modal>;
}