import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {eventsCollection} from "../../PlatformWidget";
import {list} from "postcss";


interface Message {
  msg: string,
  authorName: string | null,
  authorId: string | null,
  avatar: string | null,
  date: any,
}

export default function GlobalChat(props: any) {
  const {t, i18n} = useTranslation();
  const [msgInput, setMsgInput] = useState('');
  const [messages, setMessages] = useState<Message[]>([]);
  const [currentUserId] = useState(localStorage.getItem('userId') ?? "");
  const [currentUserName, setCurrentUserName] = useState("");
  const [currentUserAvatar, setCurrentUserAvatar] = useState("");

  const mainColor = '#F7BACB';
  const secondaryColor = '#F5F5F7';

  useEffect(() => {
    eventsCollection.doc(props.eventName).collection("globalChat").orderBy("date", "asc").onSnapshot(
        (querySnapshot: any) => {
          const listMessages: any[] = [];
          querySnapshot.forEach((doc: any) => {
            listMessages.push(doc.data());
          });
          setTimeout(() => {
            setMessages(listMessages);
            scrollEndOfMessages()
          }, 0);
        });
    eventsCollection.doc(props.eventName).collection("attendees").doc(currentUserId).get().then((attendee: any) => {
      setCurrentUserName(attendee.data()?.username);
      setCurrentUserAvatar(attendee.data()?.avatar);
    })
  }, []);

  const scrollEndOfMessages = () => {
    const messageContainer = document.getElementById("message-container");
    if (messageContainer) {
      messageContainer.scrollTop = messageContainer.scrollHeight;
    }
  };

  const handleMessageInputChange = (event: any) => {
    setMsgInput(event.target.value);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (msgInput.trim() !== "") {
      const msg: Message = {
        msg: msgInput,
        authorName: currentUserName ?? 'No author',
        authorId: currentUserId,
        avatar: currentUserAvatar ?? '',
        date: new Date()
      };
      eventsCollection.doc(props.eventName).collection("globalChat").add(msg);
      setMsgInput('');
    }
  };

  const Message = ({message}: { message: Message }) => (
      <div className="tw-grid tw-grid-cols-5">
        <div className={`tw-mb-2 tw-col-span-4 tw-flex ${currentUserId === message.authorId ? "tw-col-start-2" : ""}`}>
          {message.avatar &&
          <img className='tw-self-center tw-rounded-full tw-inline-block tw-mr-2 tw-mt-4' src={message.avatar}
               style={{width: '40px'}} alt='user avatar'/>}
          <div className="tw-flex-auto">
            <small className="tw-italic ">{message.authorName} - {message.date.toDate().toLocaleString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true
            })}</small>
            <div className={"tw-py-2.5 tw-px-2 tw-rounded tw-w-full"}
                 style={{backgroundColor: `${currentUserId === message.authorId ? mainColor : secondaryColor}`}}>{message.msg}</div>
          </div>
        </div>
      </div>
  );

  return (
      <div className="chat-box tw-flex tw-flex-col tw-border-2 bg-white" style={{height: '400px'}}>
        <div id="message-container" className="tw-flex-auto tw-overflow-y-auto tw-px-3 custom-scroll">
          {messages.map((msg: Message) => <Message key={`${msg.authorId}-${msg.date}`} message={msg}/>)}
        </div>
        <form onSubmit={handleSubmit} className="tw-flex tw-gap-2 tw-pt-2">
          <input type="text" placeholder={t('globalChat.inputPlaceholder')} value={msgInput}
                 style={{border: `3px solid ${mainColor}`, borderRadius: '4px', padding: '0.5rem'}}
                 className="tw-flex-auto" onChange={handleMessageInputChange}/>
          <button type='submit' style={{
            color: '#252B4B',
            backgroundColor: mainColor,
            padding: '7px 15px',
            borderRadius: '4px'
          }}>{t('globalChat.sendMsgBtn')}
          </button>
        </form>
      </div>
  );
}
