import firebase from "firebase";
import React, {useEffect, useReducer, useState} from "react";
import {db, eventsCollection} from "../PlatformWidget/PlatformWidget";
import {CreateOrUpdateEventModal} from "./CreateOrUpdateEventModal";
import {useHistory} from "react-router";
import Logo from "../../../src/components/PlatformWidget/components/shared/Logo";

export type SocialMedias = 'twitter' | 'instagram' | 'tiktok' | 'youtube';

export interface BroadcasterInfos {
  name: string;
  image: string;
  description: string;
  socialMedias: {
    [key in SocialMedias]: string
  }
}

export interface EventDetails {
  id?: string;
  eventName: string;
  numGuests: string;
  numTables: number;
  usersPerTable: number;
  eventType: string;
  streamLink: string;
  broadcaster: BroadcasterInfos;
}

export const Manage: React.FC = () => {
  const history = useHistory();
  const [cUser, setCUser] = useState<firebase.User | null>(null);
  const [events, setEvents] = useState<EventDetails[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [eventToEdit, setEventToEdit] = useState<EventDetails>();
  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      setCUser(user);
    });
    eventsCollection.where('creator', '==', db.doc('users/' + cUser?.uid))
      .onSnapshot((querySnapshot) => {
        const userEvents: EventDetails[] = [];
        querySnapshot.forEach((doc) => {
          userEvents.push({id: doc.id, ...doc.data()} as EventDetails);
        })
        setEvents(userEvents);
      })
  }, [cUser]);

  const createTables = (num: number, eventId: string) => {
    let i = 0;
    for (; i < num; i++) {
      eventsCollection.doc(eventId).collection("tables").add({
        description: "That's a table!",
        logo: "table_logo_blue_heart.svg",
        name: `Table ${i + 1}`
      }).then((doc) => {
        console.log(doc.id);
      }).catch(err => console.error(err));
    }
  }

  const updateTables = (event: EventDetails) => {
    eventsCollection.doc(event.id).collection("tables").get().then((snapshot) => {
      console.log(snapshot);
      let i = 0;
      snapshot.forEach((doc: any) => {
        if (i >= event.numTables) {
          console.log('deleteing with i', i);
          eventsCollection.doc(event.id).collection("tables").doc(doc.id).delete();
        }
        i++;
      });
      if (i < event.numTables && event.id) {
        createTables(event.numTables - i, event.id);
      }
    });
  };

  const createOrUpdateEvent = (event: EventDetails) => {
    if (event.id) {
      console.log(event);
      eventsCollection.doc(event.id).update(event).then(() => {
        console.log('updated');
        updateTables(event);
      })
        .finally(() => {
          setEventToEdit(undefined);
          setShowModal(false);
        })
    } else {
      eventsCollection.add({
        ...event,
        creator: db.doc('users/' + cUser?.uid)
      }).then((doc) => {
        createTables(event.numTables, doc.id);
      }).finally(() => {
        setEventToEdit(undefined);
        setShowModal(false);
      })
    }
  }

  const logout = () => {
    firebase.auth().signOut().then().catch((error) => {
      console.error(error)
    });
  }

  const goToEvent = (event: EventDetails) => {
    history.push(`event/${event.id}`)
  }

  const editModal = (event: EventDetails) => {
    setEventToEdit(event);
    setShowModal(true);
  }
  const onCloseModal = () => {
    setEventToEdit(undefined);
    setShowModal(false);
  }

  return <>
    <div className="d-flex justify-content-between align-items-center p-2">
      <Logo className="mt-1"/>
      <div className="d-flex align-items-center">
        <span className="mr-2">{cUser?.displayName}</span>
        <button type="button" className="btn btn-secondary mr-2 tw-h-10" onClick={() => setShowModal(!showModal)}>
          Host an event
        </button>
        <button type="button" className="btn btn-dark" onClick={() => logout()}>Logout</button>
      </div>
    </div>
    {events.length === 0 && <div className="d-flex h-50 justify-content-center align-items-center">
        <div className="d-flex w-25 flex-column p-4 rounded-md shadow-lg">
            <div className="d-flex justify-content-center"><i className="fas fa-sad-tear fa-5x"/></div>
            <div className="tw-italic align-self-center mb-2">You haven't created an event yet.</div>
            <button type="button" className="btn btn-lg btn-secondary" onClick={() => setShowModal(!showModal)}>
                Host an event
            </button>
        </div>
    </div>}
    <div className="tw-grid tw-grid-cols-4 tw-gap-3 tw-p-5 tw-mx-28">
      {events.map(event => <div key={event.id}
                                className="card card-body tw-cursor-pointer"
                                onClick={() => goToEvent(event)}>
        <div className="tw-font-bold">{event.eventName}</div>
        <div className="tw-italic">
          {event.eventType.length > 0 ? event.eventType : 'Basic'} - {event.numGuests} guests
        </div>
        <div className="d-flex w-100 justify-content-end">
          <button type="button"
                  className="btn btn-outline-secondary btn-xs"
                  onClick={(e) => {
                    e.stopPropagation();
                    editModal(event);
                  }}><i className="fas fa-cog mr-2"/>Edit
          </button>
        </div>
      </div>)}
    </div>
    {showModal && !eventToEdit &&
    <CreateOrUpdateEventModal onCreateEvent={createOrUpdateEvent} onClose={onCloseModal}/>}
    {showModal && eventToEdit &&
    <CreateOrUpdateEventModal eventDetails={eventToEdit} onCreateEvent={createOrUpdateEvent}
                              onClose={onCloseModal}/>}
  </>;
}