import './App.css';
import React, {useEffect, useState} from 'react';

import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";

import './i18n'
import {Login} from './components/Login/Login';
import {Manage} from './components/Manage/Manage';
import firebase from 'firebase';
import "@fontsource/lexend";
import {PlatformWidget} from "./components/PlatformWidget/PlatformWidget";

const App: React.FC = () => {

  const [cUser, setCUser] = useState<firebase.User | null | undefined>(undefined);
  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      setCUser(user);
    });
  }, []);
  return (
    <div style={{minHeight: '100vh'}}>
      <Router>
        <Switch>
          {cUser === undefined && <div>Loading...</div>}
          {cUser && <>
              <Route path="/manage" component={Manage}/>
              <Route exact path="/event/">
                  <Redirect to="/manage"/>
              </Route>
              <Route path="/event/:id?" component={PlatformWidget}>
                  <PlatformWidget cUser={cUser}/>
              </Route>
              <Route exact path="/login">
                  <Redirect to="/manage"/>
              </Route>
              <Route exact path="/" component={PlatformWidget}>
                  <Redirect to="/manage"/>
              </Route>
          </>}
          {cUser === null && <>
              <Route path="/login" component={Login}/>
              <Route path="/" component={PlatformWidget}>
                  <Redirect to="/login"/>
              </Route>
          </>}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
