import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { eventsCollection, PLATFORM_WIDGET_CDN_PREFIX } from "../../PlatformWidget";
import { mainBlue } from "../Home";
import { useTranslation } from 'react-i18next';
import firebase from "firebase";
import {useHistory} from "react-router";
import Logo from "./Logo";

const NavBar = (props: any) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [showSchedule, setShowSchedule] = useState(false);
  const [showAttendees, setShowAttendees] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [searchAttendeesInput, setSearchAttendeesInput] = useState("");
  const [attendees, setAttendees] = useState<any>([]);
  const [tables, setTables] = useState<any>({});
  const [searchedAttendees, setSearchedAttendees] = useState<any>([]);

  const handleChangeLanguage = () => {
    i18n.changeLanguage(i18n.language === "fr" ? "en" : "fr");
  };

  const handleLogout = () => {
    props.triggerDisconnection();
    firebase.auth().signOut().then().catch((error) => {console.error(error)});
  };

  const handleCloseSchedule = () => setShowSchedule(false);
  const handleShowSchedule = () => setShowSchedule(true);
  const handleCloseAttendees = () => {
    setSearchAttendeesInput("");
    setShowAttendees(false);
  };
  const handleShowAttendees = () => {
    const today = new Date();
    today.setMinutes(today.getMinutes() - 5);
    eventsCollection.doc(props.eventId).collection("attendees").where("lastConnection", ">=", today).onSnapshot(
      (querySnapshot: any) => {
        const list: any[] = [];
        querySnapshot.forEach((doc: any) => {
          list.push(doc.data());
        });
        setTimeout(() => {
          setAttendees(list.sort((a: any, b: any) => a.username - b.username));
        }, 0);
      });
    eventsCollection.doc(props.eventId).collection("tables").onSnapshot(
      (querySnapshot: any) => {
        const tablesObject: any = {};
        querySnapshot.forEach((doc: any) => {
          tablesObject[doc.id] = doc.data();
        });
        setTimeout(() => {
          setTables(tablesObject);
        }, 0);
      });
    setShowAttendees(true);
  };

  const handleCloseHelp = () => setShowHelp(false);
  const handleShowHelp = () => setShowHelp(true);

  const handleSearchAttendeesInputChange = (event: any) => {
    setSearchAttendeesInput(event.target.value);
  };

  useEffect(() => {
    setSearchedAttendees(attendees.filter((attendee: any) => attendee.username.toLowerCase().includes(searchAttendeesInput.toLowerCase())))
  }, [attendees, searchAttendeesInput]);

  return (
    <div className="tw-w-full tw-mb-2 tw-flex tw-flex-inline tw-border-b tw-border-gray-300">
      <Logo className="mt-1"/>
      {/*<img className='tw-h-full tw-pl-4 tw-p-2'*/}
      {/*  src={`${PLATFORM_WIDGET_CDN_PREFIX}/LogoViolet.svg?alt=media&token=a0d865de-d716-4e3b-a77b-9946924be15c`}*/}
      {/*  alt='Logo La Rue des Femmes' />*/}
      <div className='tw-flex'>
        <span className='tw-self-center tw-font-bold' style={{ color: "black", padding: '0 20px', fontSize: '18px' }}>
          {props.eventName}
        </span>
        <button className='tw-self-center tw-font-bold' style={{ color: mainBlue, padding: '0 20px', fontSize: '18px' }}
          onClick={handleShowAttendees}>{t('navbar.attendees')}
        </button>
      </div>
      <div className='tw-flex tw-flex-grow tw-flex-row-reverse'>
        <button className='tw-self-center tw-font-bold'
          style={{ color: mainBlue, padding: '0 20px', marginRight: '10px', fontSize: '18px' }}
          onClick={handleLogout}>{t('navbar.logout')}</button>
        <button className='tw-self-center tw-font-bold' style={{ color: mainBlue, padding: '0 20px', fontSize: '18px' }}
                onClick={() => history.push('/manage')}>{t('navbar.my-events')}
        </button>
        <button className='tw-self-center tw-font-bold'
          style={{ color: mainBlue, padding: '0 20px', marginRight: '10px', fontSize: '18px' }}
          onClick={handleShowHelp}>{t('navbar.help')}
        </button>
        <button className='tw-self-center tw-font-bold' style={{ color: mainBlue, padding: '0 20px', fontSize: '18px' }}
          onClick={handleChangeLanguage}>{i18n.language === "fr" ? "EN" : "FR"}
        </button>
      </div>

      <Modal show={showAttendees} onHide={handleCloseAttendees}>
        <Modal.Header closeButton>
          <Modal.Title>{t('navbar.attendeesModal')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input id="attendee" type='text' placeholder={t('navbar.attendeesFilterPlaceholder')}
            className="tw-mb-4 tw-w-full" style={{ border: '3px solid #F7BACB', borderRadius: '4px', padding: '0.5rem' }} onChange={handleSearchAttendeesInputChange} />
          {searchedAttendees.length === 0 ?
            <span className="tw-italic">{t('navbar.attendeesNoResult')}</span> :
            searchedAttendees.map((attendee: any) =>
              <div key={attendee.username}>
                {attendee.avatar && <img className='tw-rounded-full tw-inline-block tw-mr-2 tw-mb-1' src={attendee.avatar} style={{ width: '50px' }} alt='user avatar' />}
                <span className='' style={{ fontSize: '16px' }}>
                  {attendee.username}
                </span>
                {tables[attendee.table] && <span className='tw-ml-2' style={{ fontSize: '16px' }}>
                  &bull;<span className='tw-ml-2'>{tables[attendee.table].name}</span>
                </span>}
              </div>
            )}
        </Modal.Body>
      </Modal>

        <Modal show={showHelp} onHide={handleCloseHelp}>
          <Modal.Header closeButton>
            <Modal.Title>{t('navbar.helpModal')}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{whiteSpace: 'pre-line'}}>
            <p className="tw-text-2xl tw-pb-2">{t('navbar.help.topic1.title')}</p>
            <p className="tw-pb-4">{t('navbar.help.topic1.content')}</p>
            <p className="tw-text-2xl  tw-pb-2">{t('navbar.help.topic2.title')}</p>
            <p className="tw-pb-4">{t('navbar.help.topic2.content')}</p>
            <p className="tw-text-2xl  tw-pb-2">{t('navbar.help.topic3.title')}</p>
            <p className="tw-pb-4">{t('navbar.help.topic3.content')}</p>
            <p className="tw-text-2xl  tw-pb-2">{t('navbar.help.topic4.title')}</p>
            <p className="tw-pb-4">{t('navbar.help.topic4.content')}</p>
            <p className="tw-text-2xl  tw-pb-2">{t('navbar.help.topic5.title')}</p>
            <p className="tw-pb-4">{t('navbar.help.topic5.content')}</p>
          </Modal.Body>
        </Modal>
      </div>
  );
};

export default NavBar;
