import React from "react";

export const AdSense: React.FC = () => {
  return <div className="tw-h-52">
    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"/>
    {/*{Under stream ads}*/}
    <ins className="adsbygoogle"
         style={{display: 'block'}}
         data-ad-client="ca-pub-8014770418213517"
         data-ad-slot="8507170395"
         data-ad-format="auto"
         data-full-width-responsive="true"/>
    <script>
      (adsbygoogle = window.adsbygoogle || []).push({});
    </script>
  </div>;
}