import React, {useEffect, useState} from "react";
import LoginPage from "./login-page/LoginPage";
import { Stream } from "./stream/Stream";
import Table from "./table/Table";
import GlobalChat from "./global-chat/GlobalChat";
import Navbar from "./shared/Navbar";
import {BroadcasterInfo} from "./stream/BroadcasterInfo";
import {eventsCollection} from "../PlatformWidget";
import {EventDetails} from "../../Manage/Manage";
import {AdSense} from "./sponsors/AdSense";
import firebase from "firebase";

export const mainBlue = '#252B4B';

interface HomeProps {
  triggerConnection: (username: string, avatarData: string) => void;
  eventId: string;
  eventName: string;
  streamLink: string;
  triggerDisconnection: () => void;
  currentUserId: string | null;
  cUser: firebase.User;
}

export const Home: React.FC<HomeProps> = (props) => {
  const [event, setEvent] = useState<EventDetails>();
  useEffect(() => {
    eventsCollection.doc(props.eventId).onSnapshot((doc) => {
      console.log('setting event');
      setEvent({id: doc.id, ...(doc as any).data()});
    })
  }, []);
  return (
      <>
        {!props.currentUserId ? <LoginPage triggerConnection={props.triggerConnection} eventName={props.eventId}/> :
            <div className='tw-min-h-screen	tw-h-full'
                 style={{backgroundColor: '#ECECEF', fontFamily: 'Lexend'}}>
              <div className='tw-h-20 tw-flex tw-flex-row'>
                <Navbar triggerDisconnection={props.triggerDisconnection} eventId={props.eventId} eventName={props.eventName}/>
              </div>
              <div className='tw-grid tw-grid-cols-10 tw-gap-3 tw-m-2'>
                <div className='tw-col-span-5'>
                  <Stream streamLink={props.streamLink}/>
                  <BroadcasterInfo broadcasterInfos={event?.broadcaster}/>
                </div>
                <div className='tw-col-span-5'>
                  <Table currentUserId={props.currentUserId} eventName={props.eventId} cUser={props.cUser}/>
                </div>
                {/*<div className='tw-col-span-5'>*/}
                {/*  <Actions eventName={props.eventId}/>*/}
                {/*</div>*/}
                <div className='tw-col-span-5'>
                  <AdSense/>
                  {/*<Sponsors eventName={props.eventId}/>*/}
                </div>
                <div className='tw-col-span-5'>
                  <GlobalChat eventName={props.eventId}/>
                </div>
              </div>
              {/* <Footer /> */}
            </div>
        }
      </>
  );
}
