import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translationEN from './resources/i18n/en.json';
import translationFR from './resources/i18n/fr.json';

// the translations
const resources = {
    en: {
        translation: translationEN
    },
    fr: {
        translation: translationFR
    }
};

i18n
    .use(initReactI18next)
    .init({
        lng: 'en',
        resources,
        fallbackLng: 'en',
        debug: true,
        /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
        // ns: ['translations'],
        // defaultNS: 'translations',
        keySeparator: false,
        interpolation: {
            escapeValue: false,
            formatSeparator: ','
        },
        react: {
            wait: true
        }
    });

export default i18n