import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/esm/Modal";
import { useTranslation } from "react-i18next";
import ReactCrop from "react-image-crop";
import Webcam from "react-webcam";

interface PictureSelectionProps {
    avatarOption: string;
    onSelect: () => void;
    setCroppedImageData: (imageData: string) => void;
}

export default function PictureSelection(props: PictureSelectionProps) {
    const { t, i18n } = useTranslation();
    const [showSelfieModal, setShowSelfieModal] = useState(false);
    const handleCloseSelfieModal = () => setShowSelfieModal(false);
    const [pictureState, setPictureState] = useState('picture');
    const [imageData, setImageData] = useState('');

    const imgRef: any = useRef(null);
    const [crop, setCrop] = useState({ aspect: 1, x: 135, y: 32, width: 200, height: 200 });
    const [completedCrop, setCompletedCrop] = useState(null);

    const webcamRef: any = useRef(null);
    const capture = useCallback(
        () => {
            const imageSrc = webcamRef?.current?.getScreenshot();
            setImageData(imageSrc);
            setPictureState('crop');
        },
        [webcamRef]
    );
    const backToPicture = () => {
        setImageData('');
        setPictureState('picture');
    };
    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    const videoConstraints = {
        width: 1280,
        height: 720,
        facingMode: "user"
    };

    useEffect(() => {
        if (!completedCrop || !imgRef.current) {
            return;
        }
        const image: any = imgRef.current;
        const canvas: any = document.createElement('canvas');
        const crop: any = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
        console.log('setting croppedimage data');
        props.setCroppedImageData(canvas.toDataURL());
    }, [completedCrop]);

    return (
        <>
            <button
                type="button"
                className={`tw-bg-white tw-w-full tw-py-2 tw-my-2 tw-rounded-lg tw-border hover:tw-border-black ${props.avatarOption === 'picture' || props.avatarOption === 'pictureSelected' ? 'tw-border-black' : ''}`}
                onClick={() => { props.onSelect(); setShowSelfieModal(true) }}>
                {props.avatarOption !== 'pictureSelected' ? t('pictureSelection.takePicBtn') : t('pictureSelection.changePicBtn')}
            </button>
            <Modal show={showSelfieModal} onHide={handleCloseSelfieModal} animation={false}>
                {/* animation false because: https://github.com/react-bootstrap/react-bootstrap/issues/5075 */}
                <Modal.Header closeButton>
                    <Modal.Title>{t('pictureSelection.modalTitle')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {pictureState === 'picture' && <><Webcam
                        audio={false}
                        width={1280}
                        height={720}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                    />
                        <button
                            type="button"
                            onClick={capture}
                            style={{ color: '#252B4B', backgroundColor: '#F7BACB', padding: '7px 15px', marginTop: '1em', borderRadius: '5px', width: '100%' }}>
                            {t('pictureSelection.takePhoto')}
                        </button>
                    </>}
                    {pictureState === 'crop' && <>
                        <ReactCrop
                            src={imageData}
                            onImageLoaded={onLoad}
                            circularCrop={true}
                            crop={crop}
                            onComplete={(cCrop: any) => setCompletedCrop(cCrop)}
                            onChange={(newCrop: any) => setCrop(newCrop)} />
                        <div className='tw-grid tw-grid-cols-2 tw-gap-2'>
                            <button
                                type="button"
                                onClick={backToPicture}
                                style={{ color: '#252B4B', backgroundColor: '#F7BACB', padding: '7px 15px', marginTop: '1em', borderRadius: '5px', width: '100%' }}>
                                {t('pictureSelection.backToPicture')}
                            </button>
                            <button
                                type="button"
                                onClick={() => setShowSelfieModal(false)}
                                style={{ color: '#252B4B', backgroundColor: '#F7BACB', padding: '7px 15px', marginTop: '1em', borderRadius: '5px', width: '100%' }}>
                                {t('pictureSelection.validatePhoto')}
                            </button>
                        </div>
                    </>}
                </Modal.Body>
            </Modal>
        </>
    );
}