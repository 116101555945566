import React from 'react'
import * as Type from './types'

export const Loader = (): React.ReactElement => <div>Loading meeting...</div>

export const Props: Type.Props = {
    // domain: 'jitsi.confsquare.com', // for some reason, with our domain, does not work locally (http ?)
    domain: 'meet.jit.si', // for some reason, with our domain, does not work locally (http ?)
    roomName: 'rmn',
    config: {
        prejoinPageEnabled: false,
        enableCalendarIntegration: false
    },
    interfaceConfig: {
        HIDE_INVITE_MORE_HEADER: true,
        SHOW_CHROME_EXTENSION_BANNER: false,
        SHOW_BRAND_WATERMARK: false,
        SHOW_JITSI_WATERMARK: false,
        SHOW_POWERED_BY: false,
        SHOW_DEEP_LINKING_IMAGE: false
    }
}

export const ContainerStyle: React.CSSProperties = {
    width: `min(100%, ${(400*1920/1080)}px)`, //have to ratio depending on height (?)
    maxWidth: '100%',
    height: '400px',
}

export const FrameStyle = (loading: boolean): React.CSSProperties => ({
    display: loading ? 'none' : 'block',
    width: '100%',
    height: '100%',
})