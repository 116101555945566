import React from "react";

interface StreamProps {
  streamLink: string;
}

export const Stream: React.FC<StreamProps> = ({streamLink}) => {
  return (<div>
      {/*<iframe*/}
      {/*    title="Youtube Live Stream"*/}
      {/*    src="https://www.youtube.com/embed/lmpfeIew6DE"*/}
      {/*    frameBorder="0"*/}
      {/*    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
      {/*    allowFullScreen*/}
      {/*    style={{*/}
      {/*      width: '100%', // current col-span-6 (out of 10) with gap is ~= 59vw*/}
      {/*      height: '33vw' // 59*9/16 = 33 (youtube video ratio is 16/9)*/}
      {/*    }}/>*/}
      {streamLink && <iframe
        src={streamLink ?? "https://player.twitch.tv/?channel=papadecouvre&parent=localhost"}
        frameBorder="0"
        width='100%'
        // height='33vw'
        style={{height: '27.5vw'}}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen={true}>
      </iframe>}
      {!streamLink && <div className="d-flex justify-content-center align-items-center tw-border tw-border-black" style={{height: '33vw', width: '100%'}}>
          No link was setup for the string. The event organiser can change that.
      </div>}
  </div>);
}