import React, {useEffect, useRef, useState} from "react";
import { useTranslation } from 'react-i18next';

import { eventsCollection, PLATFORM_WIDGET_CDN_PREFIX } from "../../PlatformWidget";
import { mainBlue } from "../Home";
import PictureSelection from "./PictureSelection";
import firebase from "firebase";

export default function LoginForm(props: any) {
  const [cUser, setCUser] = useState<firebase.User | null>(null);
  const [username, setUsername] = useState('');
  const [dirtyUsername, setDirtyUsername] = useState(false);
  const [state, setState] = useState('username');
  const { t, i18n } = useTranslation();
  const [avatarOption, setAvatarOption] = useState('default');
  const [croppedPhotoData, setCroppedPhotoData] = useState('data:image/gif;base64,R0lGODlhAQABAIAAAP7//wAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=='); // default is white pic
  const [croppedImageData, setCroppedImageData] = useState('data:image/gif;base64,R0lGODlhAQABAIAAAP7//wAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=='); // default is white pic

  useEffect(() => {
    const user = firebase.auth().currentUser;
    console.log(user);
    console.log(user?.displayName);
    setCUser(user);
    setUsername(user?.displayName ?? '');
    // eventsCollection.doc(props.eventName).get().then((doc: any) => {
    // });
  }, [props.eventName]);

  useEffect(() => {
    if (avatarOption === 'default') {
      setCroppedImageData(`https://ui-avatars.com/api/?background=random&name=${username}`);
    }
  }, [avatarOption, username]);

  const handleUsernameInputChange = (event: any) => {
    setUsername(event.target.value);
    setDirtyUsername(false);
  };

  const handleChangeAvatarOption = (chosenAvatarOption: string) => {
    setAvatarOption(chosenAvatarOption);
    if (chosenAvatarOption === 'default') {
      setCroppedImageData(`https://ui-avatars.com/api/?background=random&name=${username}`);
    } else if (chosenAvatarOption === 'picture' || chosenAvatarOption === 'pictureSelected') {
      setCroppedImageData(croppedPhotoData);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (username.length === 0) {
      setDirtyUsername(true);
    } else {
      props.triggerConnection(username, croppedImageData);
    }
  };

  const handleChangeLanguage = () => {
    i18n.changeLanguage(i18n.language === "fr" ? "en" : "fr");
  };

  const croppedImageSelected = (imageData: string) => {
    setCroppedPhotoData(imageData);
    setCroppedImageData(imageData);
    setAvatarOption('pictureSelected');
  };

  return (
    <div className='tw-place-self-center tw-rounded-lg tw-grid tw-grid-cols-2 tw-gap-2 tw-p-16'
      style={{ backgroundColor: '#ECECEF', fontFamily: 'Lexend' }}>
      <div className='tw-col-span-1'>
        <div className='tw-rounded-full tw-p-16 tw-m-20' style={{ border: '1px solid #252B4B' }}>
          <img className='tw-w-48 tw-p-2'
            src={`${PLATFORM_WIDGET_CDN_PREFIX}/LogoViolet.svg?alt=media&token=a0d865de-d716-4e3b-a77b-9946924be15c`}
            alt='Consfquare logo' />
        </div>
      </div>
      <div className='tw-col-span-1 tw-grid tw-grid-rows-2 tw-grid-flow-col tw-gap-4 tw-p-8 tw-relative'>
        <button className='tw-absolute tw-right-0' style={{ color: mainBlue, padding: '0 20px', fontSize: '18px' }}
          onClick={handleChangeLanguage}>{i18n.language === "fr" ? "EN" : "FR"}
        </button>
        <div className='tw-row-span-1 tw-place-self-center tw-text-center'>
          <span style={{ color: '#F7BACB', fontSize: '2em', fontWeight: 500, whiteSpace: 'pre-line' }}>
            {t('login.eventTitle')}
          </span>
          <br />
          <span style={{ color: '#252B4B', fontSize: '1em', fontWeight: 500, paddingTop: '1em' }}>
            {t('login.pageIsSample')}
          </span>
        </div>
        <div className='tw-row-span-1 tw-justify-self-center tw-w-full'>
          {state === 'username' && <form onSubmit={handleSubmit}>
            <label htmlFor='username' style={{ color: '#252B4B', fontSize: '1.1em' }}>
              {t('login.enterName')}
            </label>
            <br />
            <input id="username" type='text' placeholder='Name' defaultValue={username} onChange={handleUsernameInputChange}
              style={{ fontSize: '1.3em', padding: '0.5em', width: '100%' }} autoFocus />
            {dirtyUsername && <>
              <span style={{ color: 'red' }}>{t('login.usernameCannotBeEmpty')}</span>
            </>}
            {username.length > 0 && <div className='tw-mt-3 tw-grid tw-grid-cols-8 tw-gap-3'>
              <div className='tw-col-span-2'>
                <div>
                  <img className='tw-rounded-full' style={{ width: '50px' }} src={croppedImageData} alt="Avatar." />
                </div>
              </div>
              <div className='tw-col-span-3'>
                <button
                  type="button"
                  className={`tw-bg-white tw-w-full tw-py-2 tw-my-2 tw-rounded-lg tw-border hover:tw-border-black ${avatarOption === 'default' ? 'tw-border-black' : ''}`}
                  onClick={() => handleChangeAvatarOption('default')}>
                  {t('login.defaultAvatar')}
                </button>
              </div>
              <div className='tw-col-span-3'>
                <PictureSelection avatarOption={avatarOption}
                  onSelect={() => handleChangeAvatarOption('picture')}
                  setCroppedImageData={croppedImageSelected} />
              </div>
            </div>}
            <br />
            <button style={{
              color: '#252B4B',
              backgroundColor: '#F7BACB',
              padding: '7px 15px',
              marginTop: '1em',
              borderRadius: '5px',
              width: '100%'
            }}
              type='submit'>{t('login.joinEventBtn')}</button>
          </form>}
        </div>
      </div>
    </div>
  );
}