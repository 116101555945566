import firebase from "firebase"
import React from "react"
import { StyledFirebaseAuth } from "react-firebaseui"


export const Login: React.FC = () => {
    // Configure FirebaseUI.
    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
        signInSuccessUrl: '/manage',
        // We will display Google and Facebook as auth providers.
        signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        ],
    };
    return <div className="d-flex flex-column">
        <div className="d-flex justify-content-center">
            <span className="tw-text-8xl">Confsquare</span>
        </div>
        <div className="d-flex justify-content-center mt-3">
            <p className="tw-text-xl">Please sign-in:</p>
        </div>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
    </div>
}