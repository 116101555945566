import firebase from "firebase";
import React, { Suspense, useEffect, useState } from "react";
import { Route, Switch, useParams } from "react-router";
import { Home } from "./components/Home";
import 'firebase/firestore';

export const PLATFORM_WIDGET_CDN_PREFIX = 'https://firebasestorage.googleapis.com/v0/b/confsquare.appspot.com/o';

const UPDATE_CONNECTION_STATUS_INTERVAL = 60000;

const firebaseConfig = {
  apiKey: "AIzaSyBR98K83SoaWQ1nxggJGzUDb2vHX-332W4",
  authDomain: "confsquare.firebaseapp.com",
  projectId: "confsquare",
  storageBucket: "confsquare.appspot.com",
  messagingSenderId: "898863935495",
  appId: "1:898863935495:web:705d5aac45957f5d0dfaa2"
};

firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const firestore = firebase.firestore;
export const eventsCollection = db.collection('events');

interface PlatformWidgetProps {
  cUser: firebase.User;
}

export const PlatformWidget: React.FC<PlatformWidgetProps> = ({cUser}) => {
  const [currentUserId, setCurrentUserId] = useState<string | null>(localStorage.getItem("userId") ?? null);
  const [streamLink, setStreamLink] = useState('');
  const [eventName, setEventName] = useState('');
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    eventsCollection.doc(id).get().then((doc) => {
      const data = doc.data();
      setStreamLink(data?.streamLink ?? '');
      setEventName(data?.eventName ?? 'No-name Event');
    })
    const interval = setInterval(() => {
      updateCurrentUserLastConnection();
    }, UPDATE_CONNECTION_STATUS_INTERVAL);

    return () => clearInterval(interval);
  }, []);

  const triggerConnection = (username: string, avatarData: string) => {
    setNewUserConnected(username, avatarData).then((userId: string) => {
      localStorage.setItem("userId", userId);
      setCurrentUserId(userId);
    });
  };

  const triggerDisconnection = () => {
    localStorage.removeItem("userId");
    setCurrentUserId(null);
  };

  const setNewUserConnected = (username: string, avatarData: string) => {
    console.log('new user connected');
    console.log(id);
    return eventsCollection.doc(id).collection("attendees").add({
      username: username,
      avatar: avatarData,
      table: null,
      lastConnection: new Date()
    }).then(function (docRef) {
      console.log('collection attendees created?');
      return docRef.id;
    });
  };

  const updateCurrentUserLastConnection = () => {
    if (currentUserId) {
      eventsCollection.doc(id).collection("attendees").doc(currentUserId).update({
        lastConnection: new Date()
      })
    }
  };

  return (
    <Suspense fallback={null}>
      <Switch>
        <Route path="/" component={Home}>
          <Home triggerConnection={triggerConnection} triggerDisconnection={triggerDisconnection}
                currentUserId={currentUserId} eventId={id} eventName={eventName} streamLink={streamLink}
          cUser={cUser}/>
        </Route>
      </Switch>
    </Suspense>
  );
}
