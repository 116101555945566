import {BroadcasterInfos, SocialMedias} from "../../../Manage/Manage";
import twitterLogo from '../../../../resources/sm_logos/twitter_logo_blue.svg';
import tiktokLogo from '../../../../resources/sm_logos/tiktok_logo.svg';
import instagramLogo from '../../../../resources/sm_logos/instagram_logo.png';
import youtubeLogo from '../../../../resources/sm_logos/youtube_logo.png';
import React from "react";

const smLogos: { [key in SocialMedias]: string } = {
  'twitter': twitterLogo,
  'instagram': instagramLogo,
  'tiktok': tiktokLogo,
  'youtube': youtubeLogo
}

export interface BroadcasterInfoProps {
  broadcasterInfos?: BroadcasterInfos;
}

export const BroadcasterInfo: React.FC<BroadcasterInfoProps> = ({broadcasterInfos}) => {
  return <>
    {broadcasterInfos && Object.keys(broadcasterInfos).length > 0 && <div className="d-flex flex-row justify-content-between bg-white">
      <div className="d-flex flex-auto m-1">
        {broadcasterInfos?.name}
      </div>
      <div className="d-flex flex-auto">
        <div className="custom-scroll" style={{maxHeight: '6em', overflowY: 'auto'}}>
          {broadcasterInfos?.description}
        </div>
      </div>
      <div className="d-flex flex-auto align-items-center">
        {broadcasterInfos?.socialMedias && Object.entries(broadcasterInfos?.socialMedias).map(([smn, smv]) =>
          <div key={smn} className="m-1" style={{height: '30px', width: '30px'}}>
            <a href={smv} target="_blank" rel="noopener">
              <img src={smLogos[smn as SocialMedias]} alt={`${smn} logo`}/>
            </a>
          </div>)}
      </div>
    </div>}
  </>
}