declare global {
    interface Window {
        JitsiMeetExternalAPI: any;
    }
}

export const importJitsiApi = (): Promise<void> => new Promise((resolve) => {
    if (window.JitsiMeetExternalAPI) {
        resolve(window.JitsiMeetExternalAPI)
    } else {
        const head = document.getElementsByTagName("head")[0];
        const script = document.createElement("script");

        script.setAttribute("type", "text/javascript");
        script.addEventListener("load", function (event: any) {
            resolve(window.JitsiMeetExternalAPI)
        }, true);
        // script.setAttribute("src", "https://jitsi.confsquare.com/external_api.js");
        script.setAttribute("src", "https://meet.jit.si/external_api.js");

        head.appendChild(script);
    }
})